<script>
// @ts-expect-error no type for svg imports
import icons from '@asar-studio/rusdram-svgs/dist/icons.svg'

export default {
  name: 'RIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return { spritePath: `${icons}#${props.icon}` }
  },
}
</script>

<template>
  <svg>
    <use v-bind="{ 'href': spritePath }" />
  </svg>
</template>
